import { get } from 'lodash';
import { useCallback, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormSection,
  OverlayCloseButton,
  Size,
} from '@pledge-earth/product-language';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import type {
  GetRegistryQuery,
  Registry,
  S3Object,
  S3ObjectInput,
} from '../../services/graphql/generated';
import {
  S3FolderEnum,
  UpsertRegistryDocument,
} from '../../services/graphql/generated';
import { EntityAdded } from '../../components/AddEntity/EntityAdded/EntityAdded';
import { showSuccessToast } from '../../utils/toast';
import { useRootFormError } from '../../components/ReactHookForm/useRootFormError';
import { defaultUseFormProps } from '../../components/ReactHookForm/defaultUseFormProps';
import { ImageUploadControlled } from '../../components/ReactHookForm/ImageUploadControlled';
import { FormErrors } from '../../components/ReactHookForm/FormErrors';
import { TextFieldControlled } from '../../components/ReactHookForm/TextFieldControlled';
import { CheckboxControlled } from '../../components/ReactHookForm/CheckboxControlled';

interface FormData {
  name: string;
  website: string;
  logo: S3Object;
  infrastructure: string;
  registryRetirementFeePerTonne: number;
  hasAutomatedRetirement: boolean;
}

export function RegistryEdit({
  closeModal,
  onSaved,
  registry = {} as Registry,
}: {
  closeModal: () => void;
  registry?: GetRegistryQuery['registry'];
  onSaved?: () => void;
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const formId = useId();
  const [logoObj, setLogoObj] = useState<S3ObjectInput | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdRegistryId, setCreatedRegistryId] = useState<string>();

  const [upsertRegistryMutation, { loading, error }] = useMutation(
    UpsertRegistryDocument,
  );

  const handleFormFinished = useCallback(
    async (values: FormData) => {
      const {
        name,
        website,
        infrastructure,
        hasAutomatedRetirement,
        registryRetirementFeePerTonne,
      } = values;
      const media = logoObj ? [logoObj] : registry.media;

      const retirementFee =
        registryRetirementFeePerTonne > 0
          ? Number(registryRetirementFeePerTonne)
          : 0;

      const result = await upsertRegistryMutation({
        variables: {
          data: {
            id: registry.id,
            name,
            website,
            infrastructure,
            has_automated_retirement: hasAutomatedRetirement,
            registry_retirement_fee_per_tonne: retirementFee,
            media,
          },
        },
      });

      const registryId = result.data?.upsert_registry?.public_id;

      if (onSaved) {
        showSuccessToast({
          description: 'Registry has been updated',
        });
        onSaved();
      } else {
        setCreatedRegistryId(registryId);
        setShowSuccess(true);
      }
    },
    [onSaved, registry, logoObj, upsertRegistryMutation],
  );

  const handleLogoUpload = useCallback(
    (file: S3Object | null) => {
      if (file) {
        setLogoObj({
          location: file.location,
          eTag: file.eTag,
          bucket: file.bucket,
          key: file.key,
        });
      } else {
        setLogoObj(null);
      }
    },
    [setLogoObj],
  );

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const redirectToRegistryDetailsPage = useCallback(() => {
    if (createdRegistryId) {
      navigate(`/offsetting/registries/${createdRegistryId}`);
    }
    closeModal();
  }, [createdRegistryId, closeModal, navigate]);

  const { control, formState, handleSubmit } = useForm<FormData>({
    ...defaultUseFormProps,
    errors: useRootFormError(error),
    defaultValues: {
      name: registry?.name ?? '',
      website: registry?.website ?? '',
      logo: registry?.media?.[0],
      infrastructure: registry?.infrastructure ?? '',
      registryRetirementFeePerTonne:
        registry?.registry_retirement_fee_per_tonne ?? 0,
      hasAutomatedRetirement: registry?.has_automated_retirement ?? false,
    },
  });

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle id="manage-registry-heading">
          {get(registry, 'id') ? (
            <FormattedMessage id="edit-registry.title" />
          ) : (
            <FormattedMessage id="registry.add.title" />
          )}
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {showSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Registry Added"
            actionButtonCTAText="View Registry"
            actionButtonCTA={redirectToRegistryDetailsPage}
          />
        ) : null}

        {!showSuccess ? (
          <Form
            id={formId}
            aria-labelledby="manage-registry-heading"
            noValidate={true}
            onSubmit={handleSubmit(handleFormFinished)}
          >
            <FormErrors formState={formState} />

            <FormSection>
              <ImageUploadControlled
                control={control}
                name="logo"
                title={formatMessage({
                  id: 'registry.edit.upload_logo',
                })}
                onUploadImage={handleLogoUpload}
                folder={S3FolderEnum.Registry}
                logo={registry?.media?.[0] as any}
              />

              <TextFieldControlled
                control={control}
                name="name"
                label={<FormattedMessage id="registry.edit.name" />}
                size={Size.Loose}
                rules={{
                  required: formatMessage({
                    id: 'registry.edit.name.required',
                  }),
                }}
              />

              <TextFieldControlled
                control={control}
                name="website"
                label={<FormattedMessage id="registry.edit.website" />}
                size={Size.Loose}
                rules={{
                  required: formatMessage({
                    id: 'registry.edit.website.required',
                  }),
                  pattern: {
                    value:
                      /(https:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                    message:
                      'Website address is invalid, expected url in format https://example.com/optional/path',
                  },
                }}
              />

              <TextFieldControlled
                control={control}
                name="infrastructure"
                label={<FormattedMessage id="registry.edit.infrastructure" />}
                size={Size.Loose}
              />

              <TextFieldControlled
                control={control}
                name="registryRetirementFeePerTonne"
                label={
                  <FormattedMessage id="registry.edit.retirement_fee_per_tonne" />
                }
                size={Size.Loose}
              />

              <CheckboxControlled
                control={control}
                name="hasAutomatedRetirement"
              >
                <FormattedMessage id="registry.edit.has-automated-retirement" />
              </CheckboxControlled>
            </FormSection>
          </Form>
        ) : null}
      </DialogBody>

      {!showSuccess && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={loading}
          >
            <FormattedMessage id="save" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
