import { get } from 'lodash';
import { useCallback, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Size,
  Dialog,
  DialogHeader,
  DialogTitle,
  OverlayCloseButton,
  DialogBody,
  DialogFooter,
  Form,
  FormSection,
} from '@pledge-earth/product-language';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import type {
  GetProjectOwnerQuery,
  ProjectOwner,
} from '../../services/graphql/generated';
import { UpsertProjectOwnerDocument } from '../../services/graphql/generated';
import { EntityAdded } from '../../components/AddEntity/EntityAdded/EntityAdded';
import { showSuccessToast } from '../../utils/toast';
import { defaultUseFormProps } from '../../components/ReactHookForm/defaultUseFormProps';
import { useRootFormError } from '../../components/ReactHookForm/useRootFormError';
import { FormErrors } from '../../components/ReactHookForm/FormErrors';
import { TextFieldControlled } from '../../components/ReactHookForm/TextFieldControlled';

interface FormData {
  name: string;
  description: string;
}

export function ProjectOwnerEdit({
  closeModal,
  onSaved,
  projectOwner = {} as ProjectOwner,
}: {
  closeModal: () => void;
  projectOwner?: GetProjectOwnerQuery['project_owner'];
  onSaved?: () => void;
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const formId = useId();
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdProjectOwnerId, setCreatedProjectOwnerId] = useState<string>();

  const [upsertProjectOwnerMutation, { loading, error }] = useMutation(
    UpsertProjectOwnerDocument,
  );

  const handleFormFinished = useCallback(
    async (values: FormData) => {
      const { name, description } = values;

      const result = await upsertProjectOwnerMutation({
        variables: {
          data: {
            id: projectOwner.id,
            name,
            description,
          },
        },
      });

      const projectOwnerId = result.data?.upsert_project_owner?.id;

      if (onSaved) {
        showSuccessToast({
          description: 'Project owner has been updated',
        });
        onSaved();
      } else {
        setCreatedProjectOwnerId(projectOwnerId);
        setShowSuccess(true);
      }
    },
    [projectOwner.id, onSaved, upsertProjectOwnerMutation],
  );

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const redirectToProjectOwnerDetailsPage = useCallback(() => {
    if (createdProjectOwnerId) {
      navigate(`/offsetting/project-owners/${createdProjectOwnerId}`);
    }
    closeModal();
  }, [createdProjectOwnerId, closeModal, navigate]);

  const { control, formState, handleSubmit } = useForm<FormData>({
    ...defaultUseFormProps,
    errors: useRootFormError(error),
    defaultValues: {
      name: projectOwner?.name ?? '',
      description: projectOwner?.description ?? '',
    },
  });

  const handleFormSubmit = handleSubmit((values) => handleFormFinished(values));

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle id="project-owner-heading">
          {get(projectOwner, 'id') ? (
            <FormattedMessage id="project-owner.edit.title" />
          ) : (
            <FormattedMessage id="project-owner.add.title" />
          )}
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {showSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Project Owner Added"
            actionButtonCTAText="View Project Owner"
            actionButtonCTA={redirectToProjectOwnerDetailsPage}
          />
        ) : null}

        {!showSuccess ? (
          <Form
            id={formId}
            aria-labelledby="project-owner-heading"
            noValidate={true}
            onSubmit={handleFormSubmit}
          >
            <FormErrors formState={formState} />

            <FormSection>
              <TextFieldControlled
                control={control}
                name="name"
                label={formatMessage({
                  id: 'project-owner.edit.name',
                })}
                size={Size.Loose}
                rules={{
                  required: formatMessage({
                    id: 'project-owner.edit.name.required',
                  }),
                }}
              />

              <TextFieldControlled
                control={control}
                name="description"
                label={formatMessage({
                  id: 'project-owner.edit.description',
                })}
                size={Size.Loose}
              />
            </FormSection>
          </Form>
        ) : null}
      </DialogBody>

      {!showSuccess && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={loading}
          >
            <FormattedMessage id="save" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
