import { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import store from 'store';
import { find } from 'lodash';
import type { SiderProps } from '@pledge-earth/web-components';
import { Menu, Layout } from '@pledge-earth/web-components';
import {
  Avatar,
  Button,
  DialogModal,
  IconButton,
  MenuCollapseIcon,
  MenuExpandIcon,
  PlusCircleFilledIcon,
  Text,
} from '@pledge-earth/product-language';
import { DialogTrigger } from 'react-aria-components';

import { AddNew } from '../AddEntity';
import { settingChanged } from '../../store/settings/reducers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AvatarImage } from '../AvatarImage';

export function MenuLeft() {
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const menuData = useAppSelector((state) => state.menu.menuData);
  const isMenuCollapsed = useAppSelector(
    (state) => !state.settings.isMobileView && state.settings.isMenuCollapsed,
  );
  const isMobileView = useAppSelector((state) => state.settings.isMobileView);
  const leftMenuWidth = useAppSelector((state) => state.settings.leftMenuWidth);
  const title = useAppSelector((state) => state.settings.title);
  const logo = useAppSelector((state) => state.settings.logo);

  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(
    store.get('app.menu.selectedKeys') || [],
  );
  const [openedKeys, setOpenedKeys] = useState(
    store.get('app.menu.openedKeys') || [],
  );

  const applySelectedKeys = useCallback(() => {
    const flattenItems = <T,>(items: T[], key: keyof T): T[] =>
      items.reduce<T[]>((flattenedItems, item) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);
    let selectedItem = find(flattenItems(menuData, 'children'), [
      'url',
      pathname,
    ]);

    if (!selectedItem) {
      selectedItem = menuData.find((item) => {
        if (item.sub_urls) {
          for (const subUrl of item.sub_urls) {
            if (pathname.startsWith(subUrl)) {
              return true;
            }
          }
        }
        return false;
      });
    }

    setSelectedKeys(selectedItem ? [selectedItem.key] : []);
  }, [menuData, pathname]);

  useEffect(() => {
    applySelectedKeys();
  }, [pathname, menuData, applySelectedKeys]);

  const onCollapse = (_value: unknown, type: string) => {
    // TODO: need this?
    if (type === 'responsive' && isMenuCollapsed) {
      return;
    }

    dispatch(
      settingChanged({
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      }),
    );
    setOpenedKeys([]);
  };

  const onOpenChange = (keys: string[]) => {
    store.set('app.menu.openedKeys', keys);
    setOpenedKeys(keys);
  };

  const handleClick = (e: any) => {
    store.set('app.menu.selectedKeys', [e.key]);
    setSelectedKeys([e.key]);
  };

  const openAddModal = useCallback(() => {
    setShowAddModal(true);
  }, []);

  const closeAddModal = useCallback(() => {
    setShowAddModal(false);
  }, []);

  const generateMenuItems = (pos: string) => {
    const generateItem = (item: any) => {
      const { key, title, icon, disabled, url, suffixIcon } = item;

      if (item.category) {
        return <Menu.ItemGroup key={title} title={title} />;
      }

      if (item.url) {
        return (
          <Menu.Item
            className="MenuLeft__menu-item"
            key={key}
            disabled={disabled}
            icon={icon}
          >
            {url.indexOf('http') > -1 && (
              // external links must use anchor tags instead of Link
              <a
                className="MenuLeft__menu-item__title"
                href={url}
                target={item.target}
                rel="noopener noreferrer"
              >
                {title} {suffixIcon ?? suffixIcon}
              </a>
            )}
            {!item.target && (
              <Link className="MenuLeft__menu-item__title" to={url}>
                {title} {suffixIcon}
              </Link>
            )}
          </Menu.Item>
        );
      }

      return (
        <Menu.Item
          key={key}
          className="MenuLeft__title"
          disabled={disabled}
          icon={icon}
        >
          {title}
        </Menu.Item>
      );
    };

    const generateSubmenu = (items: any) =>
      items.map((menuItem: any) => {
        if (menuItem.children) {
          return (
            <Menu.SubMenu
              title={menuItem.title}
              key={menuItem.key}
              icon={menuItem.icon}
            >
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          );
        }
        return generateItem(menuItem);
      });

    return menuData.map((menuItem) => {
      if (menuItem.position !== pos) {
        return null;
      }

      if (menuItem.children) {
        return (
          <Menu.SubMenu
            title={menuItem.title}
            key={menuItem.key}
            icon={menuItem.icon}
          >
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        );
      }

      return generateItem(menuItem);
    });
  };

  const menuSettings: SiderProps = isMobileView
    ? {
        trigger: null,
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        trigger: null,
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg' as any,
      };

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames('MenuLeft', {
        'MenuLeft--mobile': isMobileView,
      })}
    >
      <div
        className={classNames('MenuLeft__wrapper', {
          'MenuLeft__wrapper--mobile': isMobileView,
        })}
      >
        {!isMobileView && (
          <div className="MenuLeft__company">
            <Avatar variant="square">
              <AvatarImage
                src={logo}
                fallback={title.charAt(0)?.toUpperCase()}
              />
            </Avatar>
            <div
              className={classNames('MenuLeft__company__name', {
                'MenuLeft__company__name--hidden': isMenuCollapsed,
              })}
            >
              <Text>{title}</Text>
            </div>
            <IconButton
              label="Expand"
              variant="subtle"
              className={classNames('MenuLeft__unfold-button', {
                'MenuLeft__button-show': isMenuCollapsed,
              })}
              onPress={onCollapse as any}
            >
              <MenuExpandIcon />
            </IconButton>
            <IconButton
              label="Collapse"
              variant="subtle"
              className={classNames('MenuLeft__fold-button', {
                'MenuLeft__button-show': !isMenuCollapsed,
              })}
              onPress={onCollapse as any}
            >
              <MenuCollapseIcon />
            </IconButton>
          </div>
        )}

        <div className="MenuLeft__menus-wrapper">
          <Menu
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            tabIndex={1}
            className="MenuLeft__menu"
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            inlineIndent={15}
            style={{ border: 'none' }}
          >
            {generateMenuItems('top')}

            {/* Bottom Menu */}
            {/* <Menu.Divider className="MenuLeft__menu__divider" />
            {generateMenuItems("bottom")} */}
          </Menu>
        </div>

        <DialogTrigger>
          <Button
            variant="subtle"
            className="MenuLeft__add-new-button"
            onPress={openAddModal}
          >
            <PlusCircleFilledIcon />
            <div
              className={classNames('MenuLeft__add-new-button__text', {
                'MenuLeft__add-new-button__text--show': !isMenuCollapsed,
              })}
            >
              Add new
            </div>
          </Button>

          <DialogModal
            isOpen={showAddModal}
            onOpenChange={setShowAddModal}
            shouldCloseOnInteractOutside={(el) =>
              el.closest('.ant-select-dropdown') == null
            }
            size="large"
          >
            <AddNew closeModal={closeAddModal} />
          </DialogModal>
        </DialogTrigger>
      </div>
    </Layout.Sider>
  );
}
